import React from 'react';
import Layout from '../components/layout-home';
import Hero from '../components/hero';

export default () => {
  return (
    <>
      <Hero />
      <Layout />
    </>
  );
};
